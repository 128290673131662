import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import "./Styles.css";
import Sticky from "@ui/sticky";

const Actor = ({ data }) => {
    const content = normalizedData(data?.homePage?.content || []);


    return (

        <Layout pageTitle="" className="white-version">
            <Header
                data={{
                    ...data.header,
                    ...data.navigation,
                    socials: data.site.siteMetadata.socials,
                }}
            />

        <div>
            <main className="main-page-wrapper">
                <div className="rn-slider-area">
                    <div className="container">
                        <div className="row row--30 pt--100 pt_sm--50">
                            <div className="col-lg-6">
                                <div className="d-flex flex-wrap align-content-start h-100">
                                    <Sticky
                                        className="sticky-top-slider"
                                        top="200px"
                                    >
                                        <div className="banner-details-wrapper-sticky slide">
                                            <div className="thumbnail">
                                                <img
                                                    src={
                                                        "./src/assets/images/anuj-p-1.png"
                                                    }
                                                />
                                            </div>
                                            <div className="banner-title-area pt--30">
                                                <h1
                                                    className="title"
                                                    style={{ color: "#000" }}
                                                >
                                                    Hi, I’m <span>Anuj</span>
                                                    <br />
                                                    {/*<span className="span" style={{ color: '#000' }}> Software Engineer.</span>*/}
                                                </h1>
                                                <p className="disc">
                                                    Passionate and versatile
                                                    Indian actor with innate
                                                    acting quality, I have
                                                    featured in several films,
                                                    playing a wide range of
                                                    unique characters. I have
                                                    experience in theatre, film,
                                                    OTT, and television. I’ve
                                                    worked with many directors,
                                                    garnering high reputations
                                                    for my skills.  
                                                </p>
                                            </div>
                                            <div className="button-group text-center text-md-left pt--60 pt_md--40 pt_sm--40">
                                                <a
                                                    className="rn-btn"
                                                    href="#contacts"
                                                >
                                                    <span>
                                                        <span>
                                                            Download My CV
                                                        </span>
                                                    </span>
                                                </a>
                                                <a
                                                    className="rn-btn"
                                                    href="#contacts"
                                                >
                                                    <span>
                                                        <span>Contact</span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </Sticky>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                {/*
                  <div className="sticky-home-wrapper">
                    <div className="rn-about-area section-height">
                      <div className="inner">
                        <h5 className="title">About Me</h5>
                        <p className="about-disc">Hi I am a Front-End developer &amp; I can build your website as you want. I can customize plugin &amp; wordpress theme</p>
                      </div>
                    </div>
                  </div>
                  */}

                                <div className="sticky-home-wrapper">
                                    <div className="rn-about-area section-height">
                                        <div className="inner">
                                            <h5 className="title">ACTOR</h5>
                                            <p className="about-disc">
                                                Despite possessing innate acting
                                                qualities, I underwent years of
                                                training in the theatre to
                                                develop my skills and reshape
                                                myself as an actor. Recognised
                                                by The Passionate Foundation, my
                                                passion for acting and
                                                versatility in playing different
                                                types of characters have earned
                                                me several film roles in the
                                                Bollywood industry. I have been
                                                featured in many movies that
                                                screened in Cinemas, Netflix,
                                                EPIC, and many more.
                                            </p>
                                            <p className="about-disc">
                                                I have played an interesting set
                                                of characters on various
                                                platforms - stage, television
                                                and big screen. One of my most
                                                memorable roles is Rahul in
                                                Anand (2004) - an indie Telugu
                                                film that went on to become a
                                                hit. 
                                            </p>
                                            <p className="about-disc">
                                                I act in Hindi, English, and
                                                Telugu language. Working with
                                                reputable producers like Nagesh
                                                Kukunoor, Nitin Manmohan, Ronnie
                                                Screwvala, and Ram Madhvani has
                                                given me the experience required
                                                to thrive in any movie role.
                                                Connect with me to discuss your
                                                movie project. 
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="sticky-home-wrapper">
                                    <div className="rn-about-area section-height">
                                        <div className="inner">
                                            <h5 className="title">
                                                Want to see me act? Check the
                                                videos below. 
                                            </h5>

                                            <div className="flex-50-m">
                                                <div className="flex-50-i">
                                                    <iframe
                                                        width="100%"
                                                        height="215"
                                                        src="https://www.youtube.com/embed/aBnbjt3Z8_w"
                                                        title="YouTube video player"
                                                        frameborder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowfullscreen
                                                    ></iframe>
                                                </div>

                                                <div className="flex-50-i">
                                                    <iframe
                                                        width="100%"
                                                        height="215"
                                                        src="https://www.youtube.com/embed/6CHPapgCzJI"
                                                        title="YouTube video player"
                                                        frameborder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowfullscreen
                                                    ></iframe>
                                                </div>
                                                <div className="flex-50-i">
                                                    <iframe
                                                        width="100%"
                                                        height="215"
                                                        src="https://www.youtube.com/embed/KBLQFFQw8q8"
                                                        title="YouTube video player"
                                                        frameborder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowfullscreen
                                                    ></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sticky-home-wrapper">
                                    <div className="rn-about-area section-height">
                                        <div className="inner">
                                            <h5 className="title">
                                                Contact Me
                                            </h5>

                                            <p className="about-disc">
                                                Let’s connect on your event,
                                                either social or corporate. I’m
                                                available for freelance work as
                                                a master of ceremony, singer,
                                                actor, voice artist or
                                                educator. 
                                            </p>
                                            <p>Email: anujgurwara@gmail.com </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="sticky-home-wrapper">
                                    <div className="rn-about-area section-height">
                                        <div className="inner">
                                            <h5 className="title">
                                                Contact Me
                                            </h5>

                                            <p className="about-disc">
                                                Let’s connect on your project,
                                                either social or corporate. I’m
                                                available for freelance work as
                                                a master of ceremony, playback
                                                singer, actor, voice artist or
                                                educator.  
                                            </p>
                                            <p>Email: anujgurwara@gmail.com </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
        <Footer data={{ ...data.footer }} className="section-separator" />
        </Layout>
    );
};




export const query = graphql`
    query Actor {
        site {
            ...Site
        }
        header: general(section: { eq: "header-1-white" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-1" }) {
            menu {
                ...Menu01
            }
        }
        footer: general(section: { eq: "footer-1-white" }) {
            ...Footer01
        }
        homePage(title: { eq: "politician-home-white" }) {
            content {
                ...Content01
            }
        }
        allArticle(limit: 3) {
            nodes {
                ...Article
            }
        }
    }
`;

Actor.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                socials: PropTypes.arrayOf(PropTypes.shape({})),
                contact: PropTypes.shape({
                    phone: PropTypes.string,
                    email: PropTypes.string,
                }),
                getform_url: PropTypes.string,
            }),
        }),
     
        
        navigation: PropTypes.shape({}),
        header: PropTypes.shape({}),
        footer: PropTypes.shape({}),
    }),
};

export default Actor;
